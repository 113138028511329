<template>
  <ErrorMessage
    :condition="!isValid"
    message="Niepoprawny format daty."
  ></ErrorMessage>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: String,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isValid() {
      if (this.allowEmpty && !this.value) {
        return true;
      }
      return moment(this.value, "YYYY-MM-DD", true).isValid();
    },
  },
  components: {
    ErrorMessage: () => import("@/components/controls/ErrorMessage"),
  },
};
</script>